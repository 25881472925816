import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const Privateroute = ({ authenticated, ...props }) => {
  return authenticated ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  );
};

const mapStateToProps = state => ({
  authenticated: state.auth.token,
});

export default connect(mapStateToProps)(Privateroute);
