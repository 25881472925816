import React, { Component } from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'bootstrap/dist/css/bootstrap.css';

import Navigation from './components/navigation';
import Routerview from './router';
import { store, persistor } from './store';

import './App.css';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Container>
            <Router>
              <Navigation />
              <Container fluid>
                <Routerview />
              </Container>
            </Router>
            <ToastContainer />
          </Container>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
