import React, { useState } from 'react';
import DayJS from 'react-dayjs';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import axios from '../../../config/axios';
import Loader from '../../loader/progress';

import {
  Package,
  PackageTable,
  BtnOriginalPackage,
  PackageList,
  ButtonText,
  CheckboxLabel,
} from './style';

const PackageComponent = ({
  pkg,
  reimportCheckboxVisible,
  changeReimport,
  reimportChecked,
  notLastItem,
  atLeastOnePackageChecked,
  admin,
}) => {
  const [imageHidden, setImageHidden] = useState(false);
  const [copying, setCopying] = useState(false);

  const {
    api: { systemHash, version },
    identifier,
  } = pkg;

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPath = process.env.REACT_APP_API_PATH;

  const downloadPackageFile = () => {
    window.open(
      `${apiUrl}${apiPath}/application/${systemHash}/${identifier}.zip/${version}`,
    );
  };

  const downloadOriginalPackage = () => {
    window.open(
      `${apiUrl}${apiPath}/application/${systemHash}/original.zip/${version}`,
    );
  };

  const copyToProduction = async () => {
    try {
      setCopying(true);

      await axios.post(`/appconfiguration/publishStagingToProduction`, {
        systemHash,
        apiVersion: version,
      });
      toast('System erfolgreich übertragen', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast('Es ist ein Fehler aufgetreten', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setCopying(false);
    }
  };

  const showDeployToProduction = () => {
    return admin && process.env.REACT_APP_ENVIRONMENT !== 'production';
  };

  const toggleReimport = (systemHash, apiVersion) => {
    changeReimport(systemHash, apiVersion);
  };

  const showConfirmAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <h1>Bist du sicher?</h1>
            <p>Vorhandene Pakete werden dabei überschrieben!</p>
            <button onClick={onClose} className="btn btn-primary">
              Nein
            </button>
            <button
              style={{ marginLeft: 10 }}
              className="btn btn-danger"
              onClick={() => {
                copyToProduction();
                onClose();
              }}
            >
              Ja
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <Package>
        <PackageTable>
          {!imageHidden ? (
            <div className="cell image">
              <img
                src={`${apiUrl}${apiPath}/application/${systemHash}/system.jpg/${version}`}
                alt={`Vorschaubild für ${pkg.identifier}`}
                onError={() => setImageHidden(true)}
              />
            </div>
          ) : (
            <div className="cell image">Kein Vorschaubild</div>
          )}
          <div>
            <div className="row">
              <div className="cell">Beschreibung</div>
              <div className="cell">
                <PackageList>
                  {pkg.systems.map((system, index) => (
                    <li key={index}>{system.description}</li>
                  ))}
                </PackageList>
              </div>
            </div>
            <div className="row">
              <div className="cell">Name</div>
              <div className="cell">{pkg.name}</div>
            </div>
            <div className="row">
              <div className="cell">Sprache</div>
              <div className="cell">{pkg.language}</div>
            </div>
            <div className="row">
              <div className="cell">Komponenten</div>
              <PackageList>
                {pkg.systems.map((system, index) => (
                  <li key={index}>{system.components}</li>
                ))}
              </PackageList>
            </div>
            <div className="row">
              <div className="cell">Firma</div>
              <div className="cell">{pkg.company}</div>
            </div>
            <div className="row">
              <div className="cell">Regionen</div>
              <div className="cell">{pkg.markets.join(', ')}</div>
            </div>
            <div className="row">
              <div className="cell">XML Bezeichnung</div>
              <div className="cell">{pkg.identifier}</div>
            </div>
            <div className="row">
              <div className="cell">XML Zeitstempel</div>
              <div className="cell">{pkg.timestamp.created}</div>
            </div>
            <div className="row">
              <div className="cell">Importiert am</div>
              <div className="cell">
                <DayJS format="DD.MM.YYYY - HH:mm">
                  {pkg.api.creationDate}
                </DayJS>{' '}
                Uhr
              </div>
            </div>
            <div className="row">
              <div className="cell">Gültig für App-Version</div>
              <div className="cell">{version}.x</div>
            </div>
          </div>
        </PackageTable>
        {reimportCheckboxVisible ? (
          <CheckboxLabel>
            <span>Paket zum Reimport auswählen</span>
            <input
              type="checkbox"
              checked={reimportChecked}
              onChange={() => toggleReimport(systemHash, version)}
            />
          </CheckboxLabel>
        ) : (
          <>
            <Button onClick={downloadPackageFile}>
              Paket {pkg.identifier} herunterladen
            </Button>
            <BtnOriginalPackage onClick={downloadOriginalPackage}>
              Hochgeladenes Paket herunterladen
            </BtnOriginalPackage>

            {showDeployToProduction() && (
              <button
                onClick={showConfirmAlert}
                className="btn btn-danger"
                disabled={copying}
              >
                <ButtonText>
                  {copying ? (
                    <>
                      <Loader></Loader> <span>Übertragung...</span>
                    </>
                  ) : (
                    'System auf Live-Umgebung übertragen'
                  )}
                </ButtonText>
              </button>
            )}
          </>
        )}
      </Package>
      {(notLastItem || atLeastOnePackageChecked) && <hr />}
    </>
  );
};

const mapStateToProps = state => ({
  admin: state.auth.admin,
});

export default connect(mapStateToProps)(PackageComponent);
