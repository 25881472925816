import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import PrivateRoute from '../components/privateroute';
import Login from '../pages/login';
import Upload from '../pages/upload';
import Markets from '../pages/markets';
import ConfigurationUpload from '../pages/configurationUpload';
import Manage from '../pages/manage';

class RouterComponent extends Component {
  render() {
    const { authenticated, location } = this.props;
    return (
      <>
        {authenticated ? (
          <Redirect
            to={{
              pathname: '/manage',
              state: { from: location },
            }}
          />
        ) : (
          <Route exact path="/" component={Login} />
        )}
        <Switch>
          <PrivateRoute path="/upload" component={Upload} />
          <PrivateRoute path="/configuration" component={ConfigurationUpload} />
          <PrivateRoute path="/markets" component={Markets} />
          <PrivateRoute path="/manage" component={Manage} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.token,
});

export default connect(mapStateToProps)(RouterComponent);
