import styled from 'styled-components';
import { Button } from 'react-bootstrap';
export const Package = styled.div`
  margin-bottom: 20px;
`;

export const PackageTable = styled.div`
  display: table;

  div.row {
    display: table-row;

    &:nth-child(even) {
      background: #eee;
    }

    div.cell {
      padding: 2px;

      &:first-child {
        font-weight: bold;
        padding-right: 20px;
        white-space: nowrap;
      }
    }
  }

  div.cell {
    display: table-cell;
    vertical-align: top;

    &.image {
      padding-right: 15px;
      width: 20%;

      img {
        max-height: 150px;
        max-width: 100%;
        display: block;
      }
    }
  }
`;

export const BtnDeletePackage = styled(Button)`
  margin-left: 10px;
`;

export const BtnOriginalPackage = styled(Button)`
  margin-left: 10px;
  margin-right: 10px;
`;

export const PackageList = styled.ol`
  padding-left: 20px;
  margin-bottom: 0;
`;

export const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-right: 8px;
  }
`;

export const CheckboxLabel = styled.label`
  margin-top: 10px;
  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
`;
