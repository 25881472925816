import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import axios from '../config/axios';
import * as authActions from '../store/auth/actions';

const Wrapper = styled.div`
  margin: 20px 0 20px 0;
`;

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
    };
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  login = async () => {
    const { history, login } = this.props;
    const { email, password } = this.state;
    try {
      const { data } = await axios.post('auth/login/admin', {
        email,
        password,
      });
      login(data);
      history.push('/manage');
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        toast('Invalid Credentials', {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  onEmailChange = e => {
    this.setState({
      email: e.target.value,
    });
  };

  onPasswordChange = e => {
    this.setState({
      password: e.target.value,
    });
  };

  render() {
    return (
      <Wrapper>
        <Row>
          <Col sm md={{ span: 4 }}>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Benutzername</Form.Label>
                <Form.Control
                  type="text"
                  onChange={this.onEmailChange}
                  onKeyPress={this.handleKeyPress}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type="password"
                  onChange={this.onPasswordChange}
                  onKeyPress={this.handleKeyPress}
                />
              </Form.Group>

              <Button variant="primary" onClick={this.login}>
                Anmelden
              </Button>
            </Form>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default connect(null, { ...authActions })(LoginPage);
