import styled from 'styled-components';

export const FileuploadWrapper = styled.div`
  .no-margin-bottom {
    margin-bottom: 0;
  }
`;

export const ProgressBarText = styled.div``;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  width: 500px;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  ${ProgressBarText} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
