import React, { Component } from 'react';
import io from 'socket.io-client';

import { TransformLog, ErrorMessage } from './style';

export default class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socketError: null,
      log: [],
      autoScrollEnabled: false,
      autoScrollInterval: null,
    };
    this.socket = null;
  }

  componentDidMount() {
    const {
      socketIds,
      onImportFinished,
      onImportAborted,
      setTotalImports,
      setImportProgress,
      uploadedUuid,
    } = this.props;

    this.socket = io(process.env.REACT_APP_API_URL, {
      path: `${process.env.REACT_APP_API_PATH}/socket.io`,
    });

    this.socket.on('connect', () => {
      this.setState({
        autoScrollEnabled: true,
      });
    });

    this.socket.on('api_message', apiMessage => {
      let logMessage = apiMessage;
      if (typeof logMessage === 'string') {
        logMessage = { msg: logMessage };
      }

      this.setState({
        log: this.state.log.concat(logMessage),
      });

      if (logMessage.finished) {
        this.setState({
          autoScrollEnabled: false,
        });
        onImportFinished();
        this.scrollToBottom();
      } else if (logMessage.aborted) {
        this.setState({
          autoScrollEnabled: false,
        });
        onImportAborted();
        this.scrollToBottom();
      } else if (logMessage.exists) {
        this.scrollToBottom();

        setTimeout(() => {
          if (
            window.confirm(
              'Das Paket existiert bereits im System. Wenn Sie jetzt fortfahren, wird dieses Paket überschrieben!',
            )
          ) {
            this.socket.emit('overwritePackage', logMessage.uuid);
          } else {
            this.socket.emit('abortImport', logMessage.uuid);
          }
        }, 200);
      } else if (logMessage.progress) {
        setImportProgress(logMessage.progress.current);
        setTotalImports(logMessage.progress.total);
      }
    });

    this.socket.on('api_error', apiError => {
      if (typeof apiError.msg === 'object') {
        apiError.msg = JSON.stringify(apiError.msg);
      }
      this.setState({
        socketError: apiError.msg,
      });
    });

    if (socketIds && socketIds.length > 0) {
      this.socket.emit('subscribeReimport', socketIds);
    } else {
      this.socket.emit('subscribeConvert', uploadedUuid);
    }
  }

  componentWillUnmount() {
    const { autoScrollInterval } = this.state;
    if (this.socket.connected) {
      this.socket.close();
    }
    this.socket = null;

    clearInterval(autoScrollInterval);
  }

  componentWillUpdate(nextProps, nextState) {
    const { autoScrollInterval } = this.state;

    if (this.state.autoScrollEnabled !== nextState.autoScrollEnabled) {
      if (nextState.autoScrollEnabled && !nextState.autoScrollInterval) {
        this.setState({
          autoScrollInterval: setInterval(this.scrollToBottom, 20),
        });
      } else {
        clearInterval(autoScrollInterval);
        this.setState({
          autoScrollInterval: null,
        });
      }
    }
  }

  scrollToBottom = () => {
    const scrollingElement = document.scrollingElement || document.body;
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  };

  render() {
    const { socketError, log } = this.state;
    const { clearForm } = this.props;

    return socketError ? (
      <>
        <ErrorMessage>Fehler: {socketError}</ErrorMessage>
        <button onClick={clearForm}>Erneut versuchen</button>
      </>
    ) : (
      <>
        <p>Protokoll:</p>
        <TransformLog>
          {log.map((l, i) => (
            <div key={i}>{l.msg}</div>
          ))}
        </TransformLog>
      </>
    );
  }
}
