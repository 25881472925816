import React, { Component } from 'react';
import styled from 'styled-components';
import FileUpload from '../components/fileupload';

const Wrapper = styled.div`
  margin: 20px 0 20px 0;
`;
export default class Upload extends Component {
  render() {
    return (
      <Wrapper>
        <FileUpload
          systemsToReimport={
            this.props.location.state
              ? this.props.location.state.systemsToReimport
              : null
          }
        />
      </Wrapper>
    );
  }
}
