import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
    0% {
      top: 2px;
      height: 16px;
    }
    50%,
    100% {
      top: 6px;
      height: 8px;
    }
`;
const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    display: inline-block;
    position: absolute;
    left: 2px;
    width: 4px;
    background: #fff;
    animation: ${loading} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    left: 2px;
    animation-delay: -0.24s;
  }
  div:nth-child(2) {
    left: 8px;
    animation-delay: -0.12s;
  }
  div:nth-child(3) {
    left: 14px;
    animation-delay: 0;
  }
`;

export default () => {
  return (
    <Loader>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  );
};
