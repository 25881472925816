import React, { Component } from 'react';
import styled from 'styled-components';

import Market from '../components/market';
import axios from '../config/axios';

const Wrapper = styled.div`
  margin: 20px 0 20px 0;
`;

const AddMarketButton = styled.button`
  margin-bottom: 15px;
`

export default class Markets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewMarketFormVisible: false,
      markets: [],
    };
  }

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  toggleNewMarketForm = () => {
    this.setState({
      createNewMarketFormVisible: !this.state.createNewMarketFormVisible,
    });
  };

  async componentDidMount() {
    await this.fetchMarkets();
  }

  fetchMarkets = async () => {
    const { data } = await axios.get('/market');
    this.setState({
      markets: data,
    });
  };

  atMarktCreated = data => {
    const { markets } = this.state;
    this.setState({
      markets: [...markets, data],
      createNewMarketFormVisible: false,
    });
  };

  atMarketRemoved = id => {
    const { markets } = this.state;
    this.setState({
      markets: markets.filter(market => market.id !== id),
    });
  };

  render() {
    const { markets, createNewMarketFormVisible } = this.state;
    return (
      <Wrapper>
        <h2>Märkte</h2>
        {markets.map((market, index) => (
          <Market
            market={market}
            key={`market-${index}`}
            atMarketRemoved={this.atMarketRemoved}
          ></Market>
        ))}
        <AddMarketButton onClick={this.toggleNewMarketForm} className="btn btn-success">
          {createNewMarketFormVisible ? '-' : '+'} Neuen Markt anlegen
        </AddMarketButton>
        {createNewMarketFormVisible && (
          <Market atMarketCreated={this.atMarktCreated}></Market>
        )}
      </Wrapper>
    );
  }
}
