import React from 'react';
import Package from './package';

export default ({
  packages,
  reimportCheckboxVisible,
  changeReimport,
  systemsToReimport,
}) => {
  return packages.map((pkg, index) => (
    <Package
      pkg={pkg}
      key={index}
      lastItem={index !== packages.length - 1 || reimportCheckboxVisible}
      reimportCheckboxVisible={reimportCheckboxVisible}
      changeReimport={changeReimport}
      reimportChecked={systemsToReimport.some(
        reimportPkg =>
          reimportPkg.systemHash === pkg.api.systemHash &&
          reimportPkg.apiVersion === pkg.api.version,
      )}
      atLeastOnePackageChecked={systemsToReimport.length > 0}
    />
  ));
};
