import styled from 'styled-components';

export const TransformLog = styled.div`
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  font-weight: 600;
  background: black;
  color: white;
  margin-top: 10px;
  padding: 10px;
  overflow-x: hidden;

  > div {
    text-overflow: ellipsis;
    word-wrap: break-word;
    hyphens: auto;

    &:not(:first-child) {
      padding-top: 2px;
    }
  }
`;

export const ErrorMessage = styled.p`
  font-size: 16px;
  color: red;
`;
