import { LOGIN, LOGOUT } from './types';
import * as JsCookie from 'js-cookie';

const initialState = {
  token: '',
  admin: false,
};

export const authReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case LOGIN:
      JsCookie.set('jwt', action.payload.token);
      return {
        token: action.payload.token,
        admin: action.payload.admin,
      };
    case LOGOUT:
      JsCookie.remove('jwt');
      return {
        token: action.payload.token,
        admin: action.payload.admin,
      };
    default:
      return state;
  }
};
