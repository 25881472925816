import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import * as authActions from '../../store/auth/actions';

const Navigation = ({ location, authenticated, admin, logout }) => {
  return authenticated ? (
    <Nav activeKey={location.pathname} variant="tabs">
      <Nav.Item>
        <Nav.Link as={Link} to="/upload">
          Neues Paket hochladen
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/manage">
          Pakete verwalten
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/configuration">
          Konfiguration hochladen
        </Nav.Link>
      </Nav.Item>
      {admin && (
        <Nav.Item>
          <Nav.Link as={Link} to="/markets">
            Märkte einpflegen
          </Nav.Link>
        </Nav.Item>
      )}
      <Nav.Item>
        <Nav.Link as={Link} to="/" onClick={logout}>
          Abmelden
        </Nav.Link>
      </Nav.Item>
    </Nav>
  ) : null;
};

const mapStateToProps = state => ({
  authenticated: state.auth.token,
  admin: state.auth.admin,
});

export default connect(mapStateToProps, {
  ...authActions,
})(withRouter(Navigation));
