import * as jwtDecode from 'jwt-decode';

import { LOGIN, LOGOUT } from './types';

export const login = token => {
  return {
    type: LOGIN,
    payload: {
      token,
      admin: Boolean(jwtDecode(token).admin),
    },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
    payload: {
      token: null,
      admin: false,
    },
  };
};
