import React, { Component } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import axios from '../config/axios';
import PackageList from '../components/packagelist';

export const PackageContext = React.createContext();

const Wrapper = styled.div`
  margin: 20px 0 20px 0;
`;

const CheckboxLabel = styled.label`
  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
`;

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 300,
  }),
};

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systemsToReimport: [],
      reimportCheckboxVisible: false,
      packages: [],
      filteredPackages: [],
      filterData: {},
      activeFilters: {
        company: [],
        language: [],
        markets: [],
        appVersions: [],
      },
      filterTranslation: {
        company: 'Firma',
        language: 'Sprache',
        markets: 'Regionen',
        appVersions: 'App-Version',
      },
    };
  }

  async componentDidMount() {
    const { data: packages } = await axios.get('application/all');
    const filterData = this.extractFilterData(packages);
    this.setState({
      filteredPackages: packages,
      packages,
      filterData,
    });
  }

  extractFilterData = packages => {
    const company = [];
    const language = [];
    const markets = [];
    const appVersions = [];
    packages.forEach(entry => {
      if (!language.some(el => el.value === entry.language)) {
        language.push({
          value: entry.language,
          label: entry.language,
        });
      }
      entry.markets.forEach(market => {
        if (!markets.some(el => el.value === market)) {
          markets.push({
            value: market,
            label: market,
          });
        }
      });
      if (!company.some(el => el.value === entry.company)) {
        company.push({
          value: entry.company,
          label: entry.company,
        });
      }
      if (!appVersions.some(el => el.value === entry.api.version)) {
        appVersions.push({
          value: entry.api.version,
          label: entry.api.version,
        });
      }
    });
    return { language, markets, company, appVersions };
  };

  filterPackages = () => {
    const { packages, activeFilters } = this.state;
    const filteredPackages = packages.filter(entry => {
      return Object.keys(activeFilters).every(filter => {
        if (activeFilters[filter].length === 0) {
          return true;
        }

        if (filter === 'appVersions') {
          return activeFilters[filter].some(el => entry.api.version === el);
        }

        if (typeof entry[filter] === 'string') {
          return activeFilters[filter].some(el => entry[filter] === el);
        }
        return entry[filter].some(val =>
          activeFilters[filter].some(sel => val === sel),
        );
      });
    });

    this.setState({
      filteredPackages,
    });
  };

  handleSelectChange = (key, selected) => {
    const { activeFilters } = this.state;
    const newFilters = key
      ? {
          ...activeFilters,
          [key]: selected ? selected.map(sel => sel.value) : [],
        }
      : { ...activeFilters };

    this.setState(
      {
        activeFilters: newFilters,
      },
      () => this.filterPackages(),
    );
  };

  toggleReimport = () => {
    const { reimportCheckboxVisible } = this.state;
    this.setState({
      reimportCheckboxVisible: !reimportCheckboxVisible,
    });
  };

  toggleAllForReimport = () => {
    const { systemsToReimport, filteredPackages } = this.state;
    this.setState({
      systemsToReimport:
        systemsToReimport.length !== filteredPackages.length
          ? this.state.filteredPackages.map(pkg => ({
              systemHash: pkg.api.systemHash,
              apiVersion: pkg.api.version,
            }))
          : [],
    });
  };

  startReimport = () => {
    const { systemsToReimport } = this.state;
    this.props.history.push('/upload', { systemsToReimport });
  };

  changeReimport = (systemHash, apiVersion) => {
    const { systemsToReimport } = this.state;

    this.setState({
      systemsToReimport: systemsToReimport.some(
        sys => sys.systemHash === systemHash && sys.apiVersion === apiVersion,
      )
        ? systemsToReimport.filter(
            system =>
              !(
                system.systemHash === systemHash &&
                system.apiVersion === apiVersion
              ),
          )
        : systemsToReimport.concat({
            systemHash,
            apiVersion,
          }),
    });
  };

  reimportButtonVisible = () => {
    const { reimportCheckboxVisible, systemsToReimport } = this.state;
    return reimportCheckboxVisible && systemsToReimport.length > 0;
  };

  render() {
    const {
      filteredPackages,
      filterData,
      filterTranslation,
      reimportCheckboxVisible,
      systemsToReimport,
    } = this.state;
    const { admin } = this.props;
    return (
      <div>
        <Wrapper>
          <h2>Pakete verwalten</h2>
          <p>
            Um ein Paket zu aktualisieren, laden Sie ein neues Paket über 'Neues
            Paket hochladen' hoch. Folgende Werte müssen gleich sein, damit ein
            bestehendes Paket aktualisiert wird:
          </p>

          <ul>
            <li>Bezeichnung</li>
            <li>Firma</li>
            <li>Sprache</li>
          </ul>
        </Wrapper>
        <Wrapper>
          <h4>Filter</h4>

          <Row>
            {Object.keys(filterData).map((filter, index) => (
              <Col sm key={index}>
                <label>
                  {filterTranslation[filter]}
                  <Select
                    styles={customStyles}
                    isMulti
                    onChange={selected =>
                      this.handleSelectChange(filter, selected)
                    }
                    options={filterData[filter]}
                  />
                </label>
              </Col>
            ))}
          </Row>
        </Wrapper>

        <Wrapper>
          {admin && (
            <>
              <CheckboxLabel>
                <span>Pakete reimportieren</span>
                <input type="checkbox" onChange={this.toggleReimport} />
              </CheckboxLabel>
              <br />
              {reimportCheckboxVisible && (
                <CheckboxLabel>
                  <span>Alle auswählen</span>
                  <input
                    type="checkbox"
                    checked={
                      systemsToReimport.length === filteredPackages.length
                    }
                    onChange={this.toggleAllForReimport}
                  />
                </CheckboxLabel>
              )}
            </>
          )}
        </Wrapper>
        {filteredPackages && filteredPackages.length > 0 ? (
          <>
            <p>
              Nachfolgend alle Pakete, die im System importiert sind
              aufgelistet:
            </p>

            <PackageList
              packages={filteredPackages}
              reimportCheckboxVisible={reimportCheckboxVisible}
              changeReimport={this.changeReimport}
              systemsToReimport={systemsToReimport}
            />
          </>
        ) : (
          <p>
            Derzeit sind noch keine Pakete importiert. Importieren Sie ein neues
            Paket über 'Neues Paket hochladen'.
          </p>
        )}
        {this.reimportButtonVisible() && (
          <Button onClick={this.startReimport}>Pakete neu importieren</Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  admin: state.auth.admin,
});

export default connect(mapStateToProps)(Manage);
