import axios from 'axios';
import * as JsCookie from 'js-cookie';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PATH}`,
});

instance.interceptors.request.use(
  config => {
    if (JsCookie.get('jwt')) {
      config.withCredentials = true;
    }

    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default instance;
