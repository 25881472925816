import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, ProgressBar } from 'react-bootstrap';
import Logs from '../log';
import axios from '../../config/axios';

import {
  FileuploadWrapper,
  ProgressBarWrapper,
  ProgressBarText,
} from './style';

const FileUpload = function({ systemsToReimport }) {
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [uploadedUuid, setUploadedUuid] = useState(null);
  const [zipPackage, setZipPackage] = useState(false);
  const [uploadFinished, setUploadFinished] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [importFinished, setImportFinished] = useState(false);
  const [importAborted, setImportAborted] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [socketIds, setSocketIds] = useState([]);
  const [totalImports, setTotalImports] = useState(0);
  const [importProgress, setImportProgress] = useState(0);

  const resetInputKey = () => setInputKey(Date.now());

  const fileSize = zipPackage
    ? (zipPackage.size / 1024 / 1024).toFixed(2).replace('.', ',')
    : 0;

  const handleFile = e => {
    setZipPackage(e.target.files[0]);
  };

  const onUploadProgress = progressEvent => {
    setUploadProgress(
      ((progressEvent.loaded * 100) / progressEvent.total)
        .toFixed(2)
        .replace('.', ','),
    );
  };

  const upload = async () => {
    setFormDisabled(true);
    setUploadProgress(0);

    const data = new FormData();

    data.append('file', zipPackage);

    const response = await axios.post('application', data, {
      onUploadProgress,
    });

    const { data: uuid } = response;

    setUploadedUuid(uuid);
    setUploadFinished(true);
  };

  const clearForm = () => {
    setUploadProgress(null);
    setUploadedUuid(null);
    setZipPackage(false);
    setUploadFinished(false);
    setFormDisabled(false);
    setImportFinished(false);
    setImportAborted(false);
    resetInputKey();
    setImportProgress(0);
    setTotalImports(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.post(
        'application/reimport',
        systemsToReimport,
      );
      setSocketIds(data.map(sys => sys.registrationId));
    };
    if (systemsToReimport && systemsToReimport.length > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSocket = () => {
    return uploadFinished || (socketIds && socketIds.length > 0);
  };

  const showProgressBar = () => {
    return socketIds && socketIds.length > 0 && !importFinished;
  };

  return (
    <FileuploadWrapper>
      {!systemsToReimport && (
        <>
          <h2>Hochladen & Importieren</h2>
          <form onSubmit={e => e.preventDefault()}>
            <label className="btn btn-primary">
              {zipPackage ? zipPackage.name : 'Datei auswählen'}
              <input
                type="file"
                name="zipPackage"
                accept=".zip"
                disabled={formDisabled}
                onChange={handleFile}
                key={inputKey}
                hidden
              />
            </label>

            {zipPackage && (
              <div>
                <p>Es werden {fileSize} MB hochgeladen.</p>
                <p>
                  Die Datei wird sofort in das System importiert sofern keine
                  Fehler festgestellt werden. Sollten Sie die Seite während des
                  Imports verlassen oder aktualisieren, wird der Import im
                  Hintergrund trotzdem weiter ausgeführt.
                </p>

                <Button
                  variant="outline-info"
                  onClick={upload}
                  disabled={formDisabled}
                >
                  Datei hochladen, prüfen &amp; importieren
                </Button>

                {formDisabled && (
                  <div>
                    <div>
                      <p className="no-margin-bottom">
                        {uploadProgress !== -1 && !uploadFinished
                          ? `Datei wird hochgeladen ... (${uploadProgress} %)`
                          : 'Fertig'}
                      </p>
                      <progress
                        value={Number.parseFloat(uploadProgress)}
                        max={100}
                      >
                        {uploadProgress} %
                      </progress>
                    </div>
                  </div>
                )}
              </div>
            )}
          </form>
        </>
      )}

      {showSocket() && (
        <>
          {showProgressBar() && (
            <ProgressBarWrapper>
              <ProgressBar
                variant="success"
                animated
                now={((importProgress || 0) / socketIds.length) * 100}
              />
              <ProgressBarText>
                {importProgress || 0} / {socketIds.length}
              </ProgressBarText>
            </ProgressBarWrapper>
          )}

          <p>Das System wird nun importiert.</p>
          <Logs
            uploadedUuid={uploadedUuid}
            onImportFinished={() => setImportFinished(true)}
            onImportAborted={() => setImportAborted(true)}
            clearForm={clearForm}
            socketIds={socketIds}
            setImportProgress={setImportProgress}
            setTotalImports={setTotalImports}
          />
          {importFinished && (
            <>
              <p>
                Der Import wurde abgeschlossen. Klicken Sie auf die nachfolgende
                Schaltfläche um ein weiteres Paket zu importieren.
              </p>
              <Button variant="outline-info" onClick={clearForm}>
                Weiteres Paket importieren
              </Button>
              <Button variant="outline-primary">
                <Link to="manage">Pakete verwalten</Link>
              </Button>
            </>
          )}
          {importAborted && (
            <>
              <p>Der Import wurde abgebrochen.</p>
              <Button variant="outline-info" onClick={clearForm}>
                Neues Paket importieren
              </Button>
              <Button variant="outline-primary">
                <Link to="manage">Pakete verwalten</Link>
              </Button>
            </>
          )}
        </>
      )}
    </FileuploadWrapper>
  );
};

export default FileUpload;
